<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    title="Reativar Contrato"
    @click:close="visible = false"
  >
    <!-- Já está finalizado -->
    <v-row v-if="form.finished_at" class="ma-0">
      <v-col cols="12">
        <KChip medium red
          >Contrato Finalizado em {{ form.finished_at | dateBR }}
        </KChip>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-btn rounded elevation="0" @click="save">Reativar</v-btn>
      </v-col>
    </v-row>
  </KDialog>
</template>

<script>
import KChip from "@/components/KChip";
import KInputDate from "@/components/KInput/Date";
import KDialog from "@/components/KDialog";
export default {
  components: {
    KChip,
    KInputDate,
    KDialog
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      errors: {}
    };
  },

  methods: {
    async save() {
      try {
        this.form.finished_at = null;

        // console.log(this.form);
        this.loading = true;
        const result = await this.$store.dispatch(
          "consumption_contracts/save",
          {
            payload: this.form,
            editing: true
          }
        );
        this.form = {};
        this.visible = false;
        this.loading = false;
        this.$emit("save");
      } catch (error) {
        this.loading = false;
      }
    },

    openToReactivate(payload) {
      // console.log("abriu", payload);
      this.form = payload;
      this.visible = true;
    }
  }
};
</script>

<style>
</style>
