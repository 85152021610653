<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    title="Finalizar Contrato"
    @click:close="visible = false"
  >
    <!-- Não está finalizado ainda -->
    <v-row class="ma-0">
      <v-col cols="12">
        <KChip medium green>Contrato Ativo </KChip>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <KInputDate
          label="Data de Finalização do contrato"
          dense
          outlined
          v-model="form.finished_at"
          :error-messages="errors.finished_at"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <KAlert warning title="Atenção">
          Uma coleta avulsa será criada automaticamente para gerar a última
          cobrança deste contrato
        </KAlert>
      </v-col>
      <v-col cols="6" class="d-flex justify-center">
        <KInputMonth
          label="Data de Finalização do contrato"
          dense
          outlined
          v-model="singleCollect.monthYear"
          :error-messages="errors.monthYear"
        />
      </v-col>
      <v-col cols="6" class="d-flex justify-center">
        <KInputMoney
          label="Valor do M³"
          prefix="R$"
          dense
          outlined
          v-model="singleCollect.unit_price"
          :error-messages="errors.unit_price"
        />
      </v-col>
      <!--  -->

      <v-col cols="12" class="d-flex justify-center">
        <v-btn elevation="0" @click="save">Finalizar</v-btn>
      </v-col>
    </v-row>
  </KDialog>
</template>

<script>
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import KInputDate from "@/components/KInput/Date";
import KInputMoney from "@/components/KInput/Money";
import KInputMonth from "@/components/KInput/Month";
export default {
  components: {
    KChip,
    KAlert,
    KDialog,
    KInputDate,
    KInputMoney,
    KInputMonth
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      singleCollect: {},
      errors: {}
    };
  },

  methods: {
    async save() {
      try {
        if (!this.form.finished_at) {
          this.errors = {
            finished_at: "Informe a data final deste contrato"
          };

          this.$message.error("Verifique os campos em vermelho");
          return;
        }

        // Cria coleta avulsa para pegar a última medição
        await this.createSingleCollect();

        // console.log(this.form);
        this.loading = true;
        const result = await this.$store.dispatch(
          "consumption_contracts/save",
          {
            payload: this.form,
            editing: true
          }
        );

        this.form = {};
        this.visible = false;
        this.loading = false;
        this.$emit("save");
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    async createSingleCollect() {
      try {
        if (!this.singleCollect.monthYear) {
          this.errors = { monthYear: "Campo obrigatório" };
          throw {};
        }

        if (!this.singleCollect.unit_price) {
          this.errors = { unit_price: "Campo obrigatório" };
          throw {};
        }
        const [year, month] = this.singleCollect.monthYear.split("-");

        await this.$api.post("/collections/single", {
          consumption_contract_id: this.form.id,
          unit_price: this.singleCollect.unit_price,
          month: Number(month),
          year
        });
      } catch (error) {
        console.log("er", error);
        throw error;
      }
    },

    openToFinish(payload) {
      // console.log("abriu", payload);
      this.form = { ...payload };

      const date = new Date();

      this.singleCollect = {
        monthYear: `${date.getFullYear()}-${date.getMonth() + 1}`,
        unit_price: 0
      };
      this.visible = true;
    }
  }
};
</script>

<style>
</style>
