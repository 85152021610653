<template>
  <KDialog
    show-close
    :visible="visible"
    :loading="loading"
    title="Criar Coleta Avulsa"
    @click:close="clear"
    @click:add="save"
    :actions="actions"
  >
    <KForm ref="form">
      <!-- Não está finalizado ainda -->
      <v-row class="ma-0">
        <v-col cols="12">
          <KAlert info class="">
            Uma coleta avulsa só será gerada se não existirem outras coletas
            abertas para este mesmo contrato de consumo.
          </KAlert>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-center">
          <KInputMonth
            label="Data de Finalização do contrato"
            dense
            outlined
            v-model="form.monthYear"
            :error-messages="errors.monthYear"
            :rules="[$validation.isRequired]"
          />
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-center">
          <KInputMoney
            label="Valor do M³"
            prefix="R$"
            dense
            outlined
            v-model="form.unit_price"
            :error-messages="errors.unit_price"
            :rules="[
              $validation.isRequired,
              $validation.isBiggerThan(form.unit_price, 0.99),
            ]"
          />
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
// import KInputDate from "@/components/KInput/Date";
import KInputMoney from "@/components/KInput/Money";
import KInputMonth from "@/components/KInput/Month";
export default {
  components: {
    KForm,
    KAlert,
    KDialog,
    // KInputDate,
    KInputMoney,
    KInputMonth
  },

  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      errors: {},
      actions: [
        {
          eventName: "click:add",
          label: "Criar Coleta Avulsa"
        }
      ]
    };
  },

  computed: {
    payload() {
      if (this.form.monthYear) {
        const [year, month] = this.form.monthYear.split("-");

        return {
          consumption_contract_id: this.form.consumption_contract_id,
          unit_price: this.form.unit_price,
          month: Number(month),
          year
        };
      }
    }
  },

  methods: {
    async save() {
      try {
        // console.log(this.form);

        if (this.$refs.form.validate()) {
          this.loading = true;
          await this.$api.post("/collections/single", this.payload);

          this.clear();
          this.loading = false;
          this.$emit("save");
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    openToAdd(payload) {
      // console.log("abriu", payload);
      // this.form = payload;

      const date = new Date();

      this.form = {
        consumption_contract_id: payload.id,
        monthYear: `${date.getFullYear()}-${date.getMonth() + 1}`,
        unit_price: null
      };
      this.visible = true;
    },

    clear() {
      this.form = {};
      this.$refs.form.resetValidation();
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
