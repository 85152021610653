<template>
  <KPage>
    <v-row class="mb-4">
      <v-col cols="12" class="py-1" v-if="form.finished_at">
        <!-- Chip -->
        <KChip medium>
          Contrato Finalizado em {{ form.finished_at | dateBR }}
        </KChip>
      </v-col>
      <!-- CHip -->
      <v-col cols="12" class="py-1" v-else>
        <KChip medium blue> Contrato Ativo </KChip>
      </v-col>
    </v-row>

    <!-- Botões -->
    <v-row class="m-0 my-3" v-if="isEditing">
      <v-col cols="12">
        <SectionHeader title="Estatísticas" />
      </v-col>
      <v-col class="pa-0 mb-4" v-for="item in statsList" :key="item.title">
        <CardInfo v-bind="item" />
      </v-col>
    </v-row>

    <!-- Dados do condomínio -->
    <CondominiumHeader />

    <!-- Formulário -->
    <KForm ref="form" v-if="!loading">
      <!-- <v-container> -->
      <v-row class="my-0">
        <v-col cols="12" class="pb-0">
          <SectionHeader
            title="Dados do Cliente"
            :actions="[
              {
                to: `/people/add`,
                target: '_blank',
                icon: 'mdi-plus',
                label: 'Cadastrar nova pessoa',
              },
            ]"
          />
        </v-col>
        <!-- Cliente -->
        <v-col cols="12">
          <PersonInfoSelect
            dense
            outlined
            label="Cliente"
            :person="form.customer"
            :error-messages="errors.customer_id"
            :search-params="{ showRoles: '' }"
            v-model="form.customer_id"
            @clear="form.customer_id = {}"
          />
        </v-col>
        <!-- Configurações de coleta -->
        <v-col cols="12" class="pb-0">
          <SectionHeader title="Dados da Unidade" />
        </v-col>
      </v-row>

      <!-- Dados da unidade -->
      <v-row class="my-2">
        <!-- Identificação da Unidade -->
        <v-col cols="12" sm="6" md="4" class="">
          <v-text-field
            dense
            outlined
            :rules="[$validation.isRequired]"
            :error-messages="errors.unit_identification"
            label="Identificação da Unidade"
            persistent-hint
            v-model="form.unit_identification"
            required
          ></v-text-field>
        </v-col>

        <!-- Número do Medidor -->
        <v-col cols="12" sm="6" md="4" class="">
          <v-text-field
            dense
            outlined
            :rules="[$validation.isRequired]"
            :error-messages="errors.meter_number"
            label="Número do Medidor"
            persistent-hint
            v-model="form.meter_number"
            required
          ></v-text-field>
        </v-col>

        <!-- Dia de vencimento -->
        <v-col cols="12" sm="6" md="4" class="">
          <v-select
            label="Dia de vencimento"
            dense
            outlined
            v-model="form.expiration_date_id"
            :error-messages="errors.expiration_date_id"
            :items="expirationDates"
            item-text="day"
            item-value="id"
          />
        </v-col>

        <!-- Data de Início do Contrato -->
        <v-col cols="12" sm="6" md="4" class="">
          <KInputDate
            label="Início do Contrato"
            dense
            outlined
            :rules="[]"
            v-model="form.started_at"
            :error-messages="errors.started_at"
          />
        </v-col>

        <!-- Data de Início do Contrato -->
        <v-col cols="12" sm="6" md="4" class="">
          <v-switch
            disabled
            class="my-1"
            label="Enviar por e-mail?"
            inset
            :rules="[]"
            v-model="form.to_send_email"
            :error-messages="errors.to_send_email"
          />
        </v-col>

        <!-- Data de Início do Contrato -->
        <v-col cols="12" sm="6" md="4" class="">
          <v-switch
            disabled
            class="my-1"
            inset
            label="Imprimir Boleto?"
            :rules="[]"
            v-model="form.to_print"
            :error-messages="errors.to_print"
          />
        </v-col>
      </v-row>

      <v-row v-if="!isEditing" class="my-4">
        <v-col cols="12">
          <SectionHeader title="Coleta Inicial"></SectionHeader>
        </v-col>
        <v-col cols="12">
          <KAlert info>
            É necessário informar a coleta inicial durante o cadastro desta
            contrato de consumo para que as próximas coletas possam basear-se
            nesta informação para calcular o consumo do período de referência.
          </KAlert>
        </v-col>

        <v-col cols="4">
          <KInputMoney
            :precision="3"
            suffix="m³"
            dense
            outlined
            label="Coleta Inicial"
            v-model="form.initial_collect"
            :error-messages="errors.initial_collect"
          />
        </v-col>
      </v-row>

      <!-- 

          Edição

        -->
      <template v-if="isEditing">
        <v-row>
          <v-col cols="12" class="pb-0">
            <!-- Grupos -->
            <SectionHeader
              class="mb-4"
              title="Arquivos e Informações"
              subtitle="Grupos de anexos e dados relativos ao contrato"
              :actions="[
                {
                  to: '/grouping',
                  icon: 'mdi-plus',
                  label: 'Cadastrar novo grupo',
                },
                {
                  icon: 'mdi-refresh',
                  eventName: 'click:refresh',
                  label: 'Atualizar a lista de grupos',
                },
              ]"
              @click:refresh="handleRefreshGroupClick"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <GroupsList
              reference_module="consumption_contract"
              :reference_module_id="form.id"
              ref="groups-list"
            />
          </v-col>
        </v-row>
        <!-- Finalizar Contrato -->
        <!-- Data de Finalização do Contrato -->

        <SectionHeader class="mt-4" title="Finalização do Contrato" />

        <KAlert class="my-4" no-icon tsitle="Finalização do Contrato">
          <!-- Já está finalizado -->
          <template v-if="form.finished_at">
            <v-row class="ma-0 cacha">
              <v-col cols="12" class="py-1 d-flex justify-center">
                <v-btn
                  v-if="form.finished_at"
                  color="red"
                  text
                  @click="onOpenReactivateClick"
                >
                  Reativar Contrato
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <!-- Não está finalizado -->
          <template v-else>
            <v-row class="ma-0 align-center">
              <v-col cols="12" md="8" class="py-1 d-flex justify-center flex">
                <p class="my-0 pa-3 text-no-wrap">
                  <b>Finalização de contrato</b>
                </p>
                <p class="my-0 pa-3">
                  Ao finalizar, uma solicitação de coleta será criada para gerar
                  a última cobrança do contrato
                </p>
              </v-col>
              <v-col cols="12" md="4" class="py-1 d-flex justify-center">
                <v-btn class="red--text" depressed @click="onOpenFinishClick">
                  Encerrar Contrato
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </KAlert>
      </template>
      <!-- </v-container> -->

      <!--  -->
      <v-row class="ma-0 mb-8" align="center" justify="center">
        <v-btn color="accent" elevation="0" @click="save">Salvar</v-btn>
      </v-row>
    </KForm>

    <!--  -->
    <DialogFinish ref="dialog_finish" @save="load" />
    <DialogReactivate ref="dialog_reactivate" @save="load" />
    <DialogSingleCollect ref="dialog_single_collect" @save="load" />

    <!--  -->
    <SpeedDial
      v-if="isEditing"
      :actions="speedDialActions"
      @click:single_collect="onOpenToAddSingleCollect"
    />
  </KPage>
</template>

<script>
import KForm from "@/components/KForm";
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import SpeedDial from "@/components/SpeedDial";
import KInputDate from "@/components/KInput/Date";
import KInputMoney from "@/components/KInput/Money";
import SectionHeader from "@/components/SectionHeader";
import StatsLinkItem from "@/components/Stats/LinkItem";
import PersonInfoSelect from "@/components/PersonInfoSelect";

import GroupsList from "@/views/Grouping/Children/List";
import CardInfo from "@/components/CardInfo";

import DialogFinish from "./DialogFinish";
import DialogReactivate from "./DialogReactivate";
import DialogSingleCollect from "./DialogSingleCollect";

import CondominiumHeader from "@/views/Collect/condominiums/CondominiumHeader";

export default {
  name: "page-save",

  components: {
    KForm,
    KPage,
    KChip,
    KAlert,
    KDialog,
    SpeedDial,
    KInputDate,
    KInputMoney,
    SectionHeader,
    StatsLinkItem,
    PersonInfoSelect,
    CondominiumHeader,
    GroupsList,
    CardInfo,

    DialogFinish,
    DialogReactivate,
    DialogSingleCollect,
  },

  data() {
    return {
      form: {},
      stats: {},
      errors: [],
      addresses: [],
      loading: false,
      condominium: {},
      //
      isEnded: this.hasFinished,
      // SpeedDial
      speedDialActions: [
        {
          eventName: "click:single_collect",
          label: "Criar Coleta Avulsa",
          icon: "mdi-city-variant",
        },
      ],
      // Dialog que troca o status do contrato
      dialogStatus: {
        visibility: true,
      },
    };
  },

  computed: {
    isEditing() {
      return !!this.$route.params.consumption_contract_id;
    },
    expirationDates() {
      return this.$store.getters["expiration_dates/all"];
    },
    hasFinished() {
      if (this.form && this.form.finished_at) {
        return !!this.form.finished_at;
      }

      return false;
    },
    //
    statsList() {
      return [
        {
          label: "Solicitações de Coleta",
          value: this.stats.count_collections,
          icon: "mdi-cellphone-dock",
          to: `/collect/condominiums/${this.$route.params.condominium_id}/consumption_contracts/${this.$route.params.consumption_contract_id}/collections`,
        },
        // {
        //   label: "Solicitações Coleta Pendentes",
        //   value: this.stats.count_pendents,
        //   icon: "mdi-alert-outline"
        //   // to: `/collect/condominiums/${this.$route.params.condominium_id}/collections`
        // },
        {
          label: "Criar Solicitação de Coleta Avulsa",
          // value: 0,
          icon: "mdi-plus",
          click: this.onOpenToAddSingleCollect,
          // to: `/collect/condominiums/${this.$route.params.condominium_id}/supplies`
        },
        {
          label: "Relatório de Consumo",
          icon: "mdi-file-chart-outline",
          target: "_blank",
          href: `${this.$api.baseURL}/consumption_contracts/${this.$route.params.consumption_contract_id}/report`,
        },
      ];
    },
  },

  methods: {
    /**
     *
     *
     */
    async save() {
      try {
        this.$loading.show();
        this.errors = {};
        // console.log(this.form);
        const payload = {
          id: this.form.id,
          condominium_id: this.$route.params.condominium_id,
          customer_id: this.form.customer_id,
          unit_identification: this.form.unit_identification,
          meter_number: this.form.meter_number,
          expiration_date_id: this.form.expiration_date_id,
          started_at: this.form.started_at,
          finished_at: this.form.finished_at,
          initial_collect: this.form.initial_collect,
          to_print: true,
          to_send_email: false,
        };

        const result = await this.$store.dispatch(
          "consumption_contracts/save",
          {
            payload,
            editing: this.isEditing,
          }
        );

        this.$loading.hide();
        this.$message.success("Registro salvo com sucesso");
        if (!this.isEditing) {
          // console.log("é edita", condominium);
          this.$router.push(
            `/collect/condominiums/${result.condominium_id}/consumption_contracts/view/${result.id}`
          );
        }
      } catch (error) {
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
        console.log(error);
      }
    },

    /**
     * Ao clicar em finalizar contrato
     */
    onOpenFinishClick() {
      this.$refs.dialog_finish.openToFinish({ ...this.form });
    },

    /**
     * Ao clicar em reativar contrato
     */
    onOpenReactivateClick() {
      this.$refs.dialog_reactivate.openToReactivate({ ...this.form });
    },

    /**
     * Ao clicar em criar uma coleta avulsa
     */
    onOpenToAddSingleCollect() {
      this.$refs.dialog_single_collect.openToAdd({ ...this.form });
    },

    /**
     * Carrega as informações
     *
     */
    async load() {
      try {
        this.$loading.show();
        this.loading = true;

        const url = `/consumption_contracts/${this.$route.params.consumption_contract_id}`;

        const [unit, stats] = await Promise.all([
          this.$api.get(url),
          this.$api.get(url + "/stats"),
        ]);

        this.form = unit.data.data;
        this.stats = stats.data.data;
        this.isEnded = !!unit.data.finished_at;
        this.$app.title(`${this.form.unit_identification}`);

        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
        this.loading = false;
      }
    },

    /**
     * Atualiza os itens dos grupos
     */
    handleRefreshGroupClick() {
      this.$refs["groups-list"].loadItems();
    },
  },

  async mounted() {
    if (this.isEditing) {
      await this.load();
    }

    // this.loadCondominum();

    this.$store.dispatch("expiration_dates/all");
  },
};
</script>

<style>
</style>
