<template>
  <v-toolbar flat class="pl-2">
    <v-btn icon @click="refresh">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <!--  -->
    <v-col class="pa-0">
      <v-text-field
        class="mr-1"
        @keyup.enter.stop.prevent="filter"
        @click:clear="onClear"
        v-model="query.q"
        clearable
        label="Pesquise por cliente ou identificador da unidade"
        solo
        prepend-inner-icon="mdi-magnify"
        hide-details
        flat
      ></v-text-field>
    </v-col>

    <!-- Filtro por tipo -->
    <v-col class="pa-0">
      <v-select
        class="mr-1"
        label="Filtrar por Status"
        solo
        v-model="query.status"
        prepend-inner-icon="mdi-filter-variant"
        hide-details
        flat
        clearable
        @change="filter"
        item-value="value"
        item-text="text"
        :items="statuses"
      ></v-select>
    </v-col>
  </v-toolbar>
</template>

<script>
export default {
  components: {},
  props: {
    page: { type: Number, default: 1 }
  },

  data() {
    return {
      query: {}
    };
  },
  watch: {
    page(newValue, oldValue) {
      this.refresh();
    }
  },

  computed: {
    filterData() {
      return {
        page: this.page,
        condominium_id: this.$route.params.condominium_id,
        ...this.query
        // roles: this.query.roles && this.query.roles.join()
      };
    },

    statuses() {
      return [
        {
          value: "active",
          text: "Ativos"
        },
        {
          value: "inactive",
          text: "Inativos"
        }
      ];
    }
  },
  methods: {
    async filter() {
      try {
        this.$loading.show();
        await this.$store.dispatch("consumption_contracts/refresh", {
          ...this.filterData,
          page: 1
        });
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },
    /**Atualiza a lista */
    async refresh() {
      try {
        this.$loading.show();
        await this.$store.dispatch(
          "consumption_contracts/refresh",
          this.filterData
        );
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     * Ao limpar o campo de busca
     */
    onClear() {
      this.query.q = null;
      this.refresh();
    }
  }
};
</script>

<style>
</style>
