<template>
  <v-row class="my-4">
    <v-col cols="12" class="py-1">
      <SectionHeader title="Unidade" />
    </v-col>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title
          >{{ contract.condominium && contract.condominium.name }} -
          {{ contract.unit_identification }}</v-card-title
        >
        <v-card-subtitle>
          {{ contract.customer && contract.customer.name }}

          <template v-if="contract.customer && contract.customer.cpf">
            - CPF: {{ contract.customer.cpf | mask("###.###.###-##") }}
          </template>

          <template v-if="contract.customer && contract.customer.cnpj">
            - CNPJ: {{ contract.customer.cnpj | mask("##.###.###/####-##") }}
          </template>
        </v-card-subtitle>
        <v-card-subtitle v-if="$slots.default">
          <slot></slot>
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    SectionHeader
  },

  data() {
    return {
      contract: {}
    };
  },

  methods: {
    async loadCondominum() {
      try {
        const { data } = await this.$api.get(
          `/consumption_contracts/${this.$route.params.consumption_contract_id}`
        );

        this.contract = data.data;
        this.$emit("load", data.data);
      } catch (error) {
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.loadCondominum();
  }
};
</script>

<style>
</style>
