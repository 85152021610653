<template>
  <KPage>
    <CondominiumHeader>
      <KChip cyan>
        {{ pagination.total | quantity("contratos", "contrato") }} de consumo
      </KChip>
    </CondominiumHeader>

    <!--  -->
    <v-row no-gutters>
      <v-col cols="12" class="py-1">
        <SectionHeader title="Contratos de Consumo" />
      </v-col>
      <v-col cols="12" class="py-1">
        <!-- Barra de busca -->
        <FilterBar :page="page" />
      </v-col>

      <v-col>
        <v-card outlined v-if="items.length">
          <v-list class="py-0 bordered">
            <!--  -->
            <v-list-item
              v-for="item in items"
              :key="`item-${item.id}`"
              :to="`/collect/condominiums/${item.condominium_id}/consumption_contracts/view/${item.id}`"
            >
              <v-list-item-avatar>
                <v-icon>mdi-folder-home</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.unit_identification
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <!-- <AddressFormatter v-bind="item.address" /> -->
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <KChip x-small blue v-if="!item.finished_at" class="mr-1"
                    >Ativo</KChip
                  >

                  <KChip x-small v-if="item.finished_at" class="mr-1"
                    >Inativo</KChip
                  >

                  <KChip x-small orange class="mr-1">
                    Dia de Vencimento:
                    {{ item.expiration_date && item.expiration_date.day }}
                  </KChip>

                  <KChip x-small purple class="mr-1">
                    Cliente:
                    {{ item.customer && item.customer.name }}
                  </KChip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Barra de paginação -->
    <pagination-bar :pagination="pagination" v-model="page" />

    <!-- Separa o rodapé do botão de cadastrar -->
    <v-row style="height: 38px"></v-row>

    <!-- Botão FAB -->
    <SpeedDial :actions="speedDialActions" @click:add="onClickAdd" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SpeedDial from "@/components/SpeedDial";
import PaginationBar from "@/components/Pagination/Bar";
import KChip from "@/components/KChip";
import AddressFormatter from "@/components/AddressFormatter";
import SectionHeader from "@/components/SectionHeader";
import CondominiumHeader from "@/views/Collect/condominiums/CondominiumHeader";

import FilterBar from "./FilterBar";

export default {
  name: "consumption-contracts-index",

  components: {
    KPage,
    SpeedDial,
    PaginationBar,
    KChip,
    AddressFormatter,
    SectionHeader,
    CondominiumHeader,

    FilterBar
  },

  data() {
    return {
      //
      loading: false,

      //
      dialog: {
        visible: true
      },

      // Dados dos filtros
      query: {},

      page: 1,
      speedDialActions: [
        {
          eventName: "click:add",
          label: "Novo Contrato de Consumo",
          icon: "mdi-folder-home"
        }
      ],
      condominium: {}
    };
  },
  watch: {
    pagination(val, old) {
      if (old.page != val.page) this.page = val.page;
    }
  },
  computed: {
    pagination() {
      return this.$store.getters["consumption_contracts/pagination"];
    },
    // condominium() {
    //   return this.$store.getters["consumption_contracts/condominium"];
    // },
    items() {
      return this.$store.getters["consumption_contracts/all"];
    }
  },

  //
  methods: {
    onClickAdd(action) {
      this.$router.push(
        `/collect/condominiums/${this.$route.params.condominium_id}/consumption_contracts/add`
      );
    },
    /**
     * Carrega os dados pela primeira vez
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();

        await Promise.all([
          this.$store.dispatch("consumption_contracts/refresh", {
            condominium_id: this.$route.params.condominium_id
          }),
          this.loadCondominum()
        ]);

        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$message.serverError(error);
        this.$loading.hide();
        this.loading = false;
      }
    },

    async loadCondominum() {
      try {
        const { data } = await this.$api.get(
          `/condominiums/${this.$route.params.condominium_id}`
        );

        this.condominium = data.data;
      } catch (error) {
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>


